import "./HumanOfOnPoint.scss"
// import Arrow from "../../common/Arrow"
import humanImg1 from "images/Company/Career/Human1.png"
import arrowIcon from "images/Company/Career/Arrow.svg"
import { useFormatter } from "helpers/i18n"

export default function HumanOfOnPoint() {
  const { __ } = useFormatter()
  const humanOfOnPointData = [
    {
      image: humanImg1,
      name: `${__({
        defaultMessage: "Nguyen Van Lam",
      })}`,
      title: `${__({
        defaultMessage: "Management Trainee Class of 2021",
      })}`,
      text: `${__({
        defaultMessage:
          "OnPoint gives me the opportunity to learn faster and practice more. I can build my resilience under pressure and quickly adapt to changes.",
      })}`,
    },
    {
      image: humanImg1,
      name: `${__({
        defaultMessage: "Nguyen Vu Duy Phong",
      })}`,
      title: `${__({
        defaultMessage: "Graphic Design Team Leader since 2018",
      })}`,
      text: `${__({
        defaultMessage:
          "When working at OnPoint, I can boost my creativity, try new things and learn a lot from my colleagues.",
      })}`,
    },
    {
      image: humanImg1,
      name: `${__({
        defaultMessage: "Hua Thanh Nha",
      })}`,
      title: `${__({
        defaultMessage: "Senior Growth Manager since 2017",
      })}`,
      text: `${__({
        defaultMessage:
          "At OnPoint, I have gone through many ups and downs and experienced day- by-day not only the challenges of working within a start-up but also within this fast-paced e-commerce industry. In the end, difficulties are made to be overcome and it is whole-heartedly rewarding to see OnPoint hitting each milestone and attain its deserved success with a constant and ever uncompromised high spirit and motivation of the team!",
      })}`,
    },
    {
      image: humanImg1,
      name: `${__({
        defaultMessage: "Doan Nguyen Da Huong",
      })}`,
      title: `${__({
        defaultMessage: "Commercial Director since 2019",
      })}`,
      text: `${__({
        defaultMessage:
          'My 3 words to describe OnPoint would be “value", “growth" and “commitment". "Value" are the long-term values, ensuring 3-winning  relationships for: partner, end-consumer and OnPoint. "Growth" refers to our innovation and continuous development. All ideas, activities and contributions will never come true without our uncompromised “commitment" to pursuit our goals to the end.',
      })}`,
    },
    {
      image: humanImg1,
      name: `${__({
        defaultMessage: "Nguyen Le Hung",
      })}`,
      title: `${__({
        defaultMessage: "Head of Strategy since 2017",
      })}`,
      text: `${__({
        defaultMessage:
          "There are multiple things that really fascinate me about OnPoint. From the top of my mind, it is the satisfaction achieved when I see how my work can create value to the consumers, while also contribute to the success of the brands and our business partners. Furthermore, my colleagues at OnPoint are a team of highly talented, smart, gritty but also humble people, who are eager to learning, building and sharing. They bring in an energy that reinvigorate me every day. Lastly, the vision of OnPoint, to become an ecosystem that enables millions of online businesses in Southeast Asia, gives each of us the opportunity to challenge our limits with a non-exhaustive desire for exploration and self-improvement.",
      })}`,
    },
  ]

  return (
    <div className="human-of-onpoint">
      {humanOfOnPointData?.map((data, index) => {
        if (index % 2 == 0) {
          return <Item1 {...data}></Item1>
        } else {
          return <Item2 {...data}></Item2>
        }
      })}
    </div>
  )
}

function Item1({ image, name, title, text }) {
  return (
    <>
      <div className="human-of-onpoint-item">
        <div className="human-of-onpoint-img">
          <img src={image} />
        </div>
        <div className="human-of-onpoint-container">
          <div className="hunman-of-onpoint-arrow-1">
            <img src={arrowIcon} />
          </div>
          <div className="human-of-onpoint-content item-1">
            <div className="human-of-onpoint-scoll">
              <div className="human-of-onpoint-name">{name}</div>
              <div className="human-of-onpoint-title">{title}</div>
              <div className="human-of-onpoint-message">
                <i>
                  <q>{text}</q>
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
function Item2({ image, name, title, text }) {
  return (
    <>
      <div className="human-of-onpoint-item">
        <div className="human-of-onpoint-container">
          <div className="human-of-onpoint-content">
            <div className="human-of-onpoint-scoll item-2">
              <div className="human-of-onpoint-name">{name}</div>
              <div className="human-of-onpoint-title">{title}</div>
              <div className="human-of-onpoint-message">
                <q>{text}</q>
              </div>
            </div>
          </div>
        </div>
        <div className="human-of-onpoint-img">
          <div className="hunman-of-onpoint-arrow-2">
            <img src={arrowIcon} />
          </div>
          <img src={image} />
        </div>
      </div>
    </>
  )
}
